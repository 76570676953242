const Skills = [
    {
        name: 'HTML',
    },
    {
        name: 'CSS',
    },
    {
        name: 'JavaScript',
    },
    {
        name: 'React JS',
    },
    {
        name: 'Tailwind CSS',
    },
    {
        name: 'Bootstrap',
    },
    {
        name: 'SQLite',
    },
    {
        name: 'Python',
    },
    {
        name: 'Django',
    },
    {
        name: 'Django Rest Framework',
    },
    {
        name: 'C',
    },
    {
        name: 'Git',
    },


]

export default Skills